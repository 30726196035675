import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

const ProtectedRoute = ({ children }) => {
    const { isLoaded, isSignedIn } = useUser(); // Hook para obter o estado do usuário

    // Aguarda o carregamento completo dos dados do usuário
    if (!isLoaded) {
        return <div>Carregando informações do usuário...</div>; // Mostra um estado de carregamento
    }

    // Redireciona para o login se o usuário não estiver autenticado
    if (!isSignedIn) {
        return <Navigate to="/" />;
    }

    // Renderiza o conteúdo protegido se o usuário estiver autenticado
    return children;
};

export default ProtectedRoute;
