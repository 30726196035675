import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../helpers/configHelper";

const PurchasePage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        // Helper to extract query parameter
        const getQueryParam = (param) => {
            const params = new URLSearchParams(location.search);
            return params.get(param);
        };

        const checkoutSessionId = getQueryParam("checkout_session_id");

        if (checkoutSessionId) {
            // Make the POST request
            fetch(`${config.baseUrl}/purchases`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ checkout_session_id: checkoutSessionId }),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response.json();
                })
                .then(() => {
                    // Redirect to home with the query string
                    navigate(`/?checkout_session_id=${checkoutSessionId}`);
                })
                .catch(async (errorResponse) => {
                    // Handle error
                    const errorText = await errorResponse.text();
                    setError(errorText);
                });
        } else {
            setError("checkout_session_id is missing in the URL.");
        }
    }, [location.search, navigate]);

    return (
        <div>
            {error ? (
                <div>
                    <h1>Error</h1>
                    <p>{error}</p>
                </div>
            ) : (
                <p>Processing your request...</p>
            )}
        </div>
    );
};

export default PurchasePage;
