import React, { useState, useEffect } from 'react';
import { CircleX, Share, Download } from "lucide-react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loading from '../components/LoadingComponent'; // Caminho relativo para o componente Loading
import config from "../helpers/configHelper";

const Page = () => {
    const [buttons, setButtons] = useState([]);
    const [qrcodeUrl, setQrcodeUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const location = useLocation();
    const receivedPageData = location.state?.page; // Aqui você acessa os dados passados
    const navigate = useNavigate();

    useEffect(() => {
        if (!receivedPageData) {
            navigate("/")
            return
        }
        setQrcodeUrl(receivedPageData.qrcode);
        setIsLoadingPage(false)
    }, []);

    const handleButtonClick = async (button) => {
        try {
            setIsLoadingPage(true)
            const response = await fetch(`${config.baseUrl}/images/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    imageUrl1: button.url,
                    imageUrl2: qrcodeUrl,
                    position: button.position,
                }),
            });

            const data = await response.json();
            setImageUrl(data.imageUrl);
            setIsModalOpen(true);
            setIsLoadingPage(false)
        } catch (error) {
            console.error('Erro ao gerar a imagem:', error);
        }
    };

    useEffect(() => {
        const fetchButtons = async () => {
            const buttonsData = [
                { icon: "/icones/icone_meia_biscoito.png", url: `${process.env.REACT_APP_BUCKET_NAME}/resources/a4_meia_biscoito.jpg`, position: { "top": 1897, "left": 967 } },
                { icon: "/icones/icone_mickey_vermelho.png", url: `${process.env.REACT_APP_BUCKET_NAME}/resources/a4_mickey_vermelho.jpg`, position: { "top": 1646, "left": 989 } },
                { icon: "/icones/icone_bola1.png", url: `${process.env.REACT_APP_BUCKET_NAME}/resources/a4_bola1.jpg`, position: { "top": 1692, "left": 965 } },
            ];
            setButtons(buttonsData);
        };

        fetchButtons();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleShare = () => {
        if (navigator.share) {
            // Verifica se o dispositivo suporta a API Web Share
            navigator.share({
                title: 'Título da Imagem',
                text: 'Aqui está uma imagem incrível!',
                url: imageUrl, // URL da imagem para ser compartilhada
            })
                .then(() => console.log('Imagem compartilhada com sucesso!'))
                .catch((error) => console.log('Erro ao compartilhar:', error));
        } else {
            console.log('API de Web Share não suportada neste navegador');
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a'); // Cria um elemento <a> programaticamente
        link.href = imageUrl; // Define o href com a URL da imagem
        link.download = 'image-preview'; // Define o nome do arquivo a ser salvo
        link.click(); // Dispara o clique no link para iniciar o download
    };

    return (

        <div>
            {isLoadingPage ?
                <Loading />
                : (
                    <div style={styles.divpai}>
                        <h1>Selecione o modelo</h1>
                        <div style={styles.gridContainer}>
                            {buttons.map((button, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleButtonClick(button)}
                                    className="action-button edit-button"
                                >
                                    <img src={button.icon} style={styles.buttonImage} />
                                </button>
                            ))}
                        </div>

                        {isModalOpen && (
                            <div style={modalStyles.overlay}>
                                <div style={modalStyles.modal}>
                                    <button onClick={closeModal} className="action-button edit-button float-right"><CircleX size={20} /></button>
                                    <button onClick={handleShare} className="action-button edit-button float-right"><Share size={20} /></button>
                                    <button onClick={handleDownload} src={imageUrl} className="action-button edit-button float-right"><Download size={20} /></button>

                                    <img src={imageUrl} alt="Preview da Imagem" style={{ width: '100%' }} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
        </div>



    );
};

const styles = {
    divpai: {
        background_color: "aliceblue",
    },
    gridContainer: {
        background_color: "aliceblue",
        display: 'grid',
        gap: '20px',
        padding: '20px',
        justifyContent: 'center',
    },
    button: {
        width: "70vw",
        height: "70vw",
        backgroundColor: 'transparent',
        border: '2px solid #007BFF',
        borderRadius: '20px',
        padding: '35px',
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    },
    buttonImage: {
        width: '70vw',
        // height: '50px',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '100%',
        textAlign: 'center',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    }
};

const modalStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '500px',
        width: '85%',
        textAlign: 'center',
    },
    closeButton: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#007BFF',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    }
};

export default Page;
