import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SignUp, SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
import Dashboard from "./pages/Dashboard";
import "./App.css";
import Content from "./pages/Content";
import ProtectedRoute from './components/ProtectedRoute';
import Edit from './pages/Edit';
import EditFree from './pages/EditFree';
import Print from './pages/Print';
import Purchase from './pages/Purchase';
import Plan from './pages/Plan';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <Router>
      <Routes>
        {/* Página inicial com verificação de autenticação */}
        <Route
          path="/dashboard"
          element={
            <>
              <SignedIn>
                {/* <UserButton /> */}
                <Dashboard />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>
          }
        />

        {/* Página de cadastro */}
        <Route path="/sign-up" element={<SignUp />} />

        {/* Rotas protegidas */}
        <Route
          path="/edit/"
          element={
            <ProtectedRoute>
              <Edit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit/:id_page"
          element={
            <ProtectedRoute>
              <Edit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/print/:id_page"
          element={
            <ProtectedRoute>
              <Print />
            </ProtectedRoute>
          }
        />

        <Route
          path="/content/"
          element={
            <Content />
          }
        />

        <Route
          path="/content/:id_page"
          element={
            <Content />
          }
        />

        <Route
          path="/purchase"
          element={

            <ProtectedRoute>
              <Purchase />
            </ProtectedRoute>

          }
        />

        <Route
          path="/plans"
          element={

            <>
              <SignedIn>
                <Plan />
              </SignedIn>
              <SignedOut>
                <RedirectToSignIn />
              </SignedOut>
            </>

          }
        />

        <Route
          path="/"
          element={
            <LandingPage />
          }
        />

        <Route
          path="/create"
          element={
            <EditFree />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
