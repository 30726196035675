import React from 'react';
import ReactDOM from 'react-dom';
import { ClerkProvider } from '@clerk/clerk-react';
import App from './App';

const clerkPubKey = process.env.REACT_APP_CLERK_PUB_KEY;

if (!clerkPubKey) {
  throw new Error('Clerk publishable key não encontrada. Verifique o arquivo .env.local.');
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <App />
  </ClerkProvider>
);
