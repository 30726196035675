import React from 'react';
import { Heart, Check, X, Rocket, Gift, Package, FileHeart } from 'lucide-react';

const PlanCard = ({ planName, price, features, ctaText, icon: Icon, isPopular }) => {
  return (

    <div className={`plan-card ${isPopular ? 'popular' : ''}`}>
      {isPopular && <div className="popular-badge">MAIS ESCOLHIDO</div>}

      <div className="plan-header">
        <div>
          <h3>{planName}</h3>
          <div className="price">R${price}</div>
        </div>
        <Icon size={48} className="plan-icon" />
      </div>

      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>
            {feature.available ?
              <span className='price-icon'>✅</span> :
              <span className='price-icon'>❌</span>
            }
            {feature.text}
          </li>
        ))}
      </ul>

      <button className="cta-button">{ctaText}</button>
    </div>

  );
};

export default function PricingPage() {
  const plans = [
    {
      planName: "Basic",
      price: "29",
      features: [
        { text: "1 Pagina Mágica", available: true },
        { text: "1 Ano de acesso", available: true },
        { text: "Use apenas fotos", available: true },
      ],
      ctaText: "Comprar 1 pagina",
      icon: FileHeart,
      isPopular: false
    },
    {
      planName: "Premium",
      price: "49",
      features: [
        { text: "10 Paginas Mágicas", available: true },
        { text: "Acesso vitalício", available: true },
        { text: "Use fotos e vídeos", available: true },
      ],
      ctaText: "Comprar 10 páginas",
      icon: Gift,
      isPopular: true
    }
  ];

  return (
    <div className="pricing-page">
      <h1 className=""> Escolha seu pacote</h1>
      <h3> para presentear todo mundo ou uma só</h3>

      <div className="plans-grid">
        {plans.map((plan, index) => (
          <PlanCard key={index} {...plan} />
        ))}
      </div>
    </div>
  );
}

const styles = `
.price-icon {
color: green;
            margin-right: 5px;
            font-size: 25px;}

.pricing-page {
  font-family: 'Roboto', sans-serif;
    min-height: 100vh;
    background-color: #060D1B;
    color: white;
    padding: 3rem 1rem;
  }

  .pricing-page h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: white;
  }

   .pricing-page h3 {
    text-align: center;
    font-size: 1rem;
        font-weight: lighter;
    margin-bottom: 3rem;
    color: white;
  }

  .plans-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
  }

  .plan-card {
    position: relative;
    background-color: #0A1628;
    border-radius: 1rem;
    padding: 2rem;
    border: 2px solid #1a2435;
  }

  .plan-card.popular {
    border-color: #3673c7;
        box-shadow: 0 0px 25px 0px #48abe0;
  }

  .popular-badge {
    position: absolute;
    top: -12px;
    right: 1rem;
    background-color: #3673c7;
    color: white;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
  }

  .plan-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  .plan-header h3 {
    color: #94a3b8;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    text-align:left;
  }

  .price {
    font-size: 2.25rem;
    font-weight: bold;
    color: white;
  }

  .plan-icon {
    color: #3673c7;
  }

  .features {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
  }

  .features li {
  font-weight: bolder;
    display: flex;
    align-items: center;
    color: white;
    margin-bottom: 1rem;
  }

  .feature-icon {
    margin-right: 0.75rem;
    width: 20px;
    height: 20px;
    color: #64748b;
  }

  .feature-icon.available {
    color: #3673c7;
  }

  .cta-button {
    width: 100%;
    background-color: #3673c7;
    color: white;
    font-weight: bold;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .cta-button:hover {
    background-color:#3dd988;
    color:black
  }

  @media (max-width: 768px) {
    .plans-grid {
      grid-template-columns: 1fr;
      max-width: 400px;
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);