import { jwtDecode } from "jwt-decode";

const parseJwtToken = (token) => {
    return jwtDecode(token);
}

const getToken = () => {
    return localStorage.getItem('token');
}

const setUserToken = (token) => {
    localStorage.setItem('token', token);
}

const isPremiumSubscriber = () => {
    const user = parseJwtToken(getToken())
    const subscriptions = user.subscriptions
    return subscriptions.some((subscriptions) => subscriptions.name == 'Premium')
}

const getUserSubscriptions = () => {
    const user = parseJwtToken(getToken())
    return user.subscriptions
}

export {
    isPremiumSubscriber,
    setUserToken,
    getUserSubscriptions
}