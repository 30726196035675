import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Eye, Edit3, Printer, QrCode, Plus, Trash, Check, X, EllipsisVertical, SlidersHorizontal } from "lucide-react";
import Loading from '../components/LoadingComponent'; // Caminho relativo para o componente Loading
import config from "../helpers/configHelper";
import { getUserSubscriptions } from "../helpers/userHelper";
import { jwtDecode } from "jwt-decode";


function Dashboard() {
    const { isLoaded, user } = useUser();
    const [pages, setPages] = useState([]);
    const [isLoadingPages, setIsLoadingPages] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
    const [isUpdatePlanModalOpen, setIsUpdatePlanModalOpen] = useState(false);
    const [pageToDelete, setPageToDelete] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [subscriptions, setSubscriptions] = useState(getSubscriptionCache())
    const navigate = useNavigate();

    useEffect(() => {
        // setSubscriptionCache(subscriptions)
        // if (subscriptions == null) return
        // const hasSubscription = subscriptions.some(subscription => (subscription.name == "Premium" || subscription.name == "Basic"))
        // if (!hasSubscription) navigate("plans")
    }, [subscriptions])

    // Fetch pages from API
    useEffect(() => {

        const init = async () => {
            const email = user.emailAddresses[0].emailAddress
            const id_user = user.id

            if (userIsLoggedIn() == false) return
            setIsLoadingPages(true)
            const databaseUser = await findOrCreateUser({ id_user, email })
            const cachedPages = getCachePages()
            if (cachedPages) setPages(cachedPages);
            if (cachedPages) setIsLoadingPages(false)
            // if (!subscriptions) {
            //     await validateSubscriptions()
            // } else {
            //     validateSubscriptions()
            // }
            const updatedPages = await fetchPages()
            setPages(updatedPages);
            setIsLoadingPages(false)
            setCachePage(updatedPages);
        }

        init()
    }, []);

    // async function validateSubscriptions() {

    //     const email = user.emailAddresses[0].emailAddress

    //     try {
    //         const response = await fetch(`${config.baseUrl}/purchases/${email}`);
    //         const { user_purchases } = await response.json();
    //         const purchase_products = user_purchases.purchases.reduce((acc, purchase) => {
    //             return [...acc, ...purchase.purchased_items]
    //         }, [])

    //         setSubscriptions(purchase_products)
    //     } catch (error) {
    //         console.error("Erro ao buscar páginas:", error);
    //     }

    // }

    async function validateSubscriptions() {

        const email = user.emailAddresses[0].emailAddress

        try {
            const response = await fetch(`${config.baseUrl}/purchases/${email}`);
            const { user_purchases } = await response.json();
            const purchase_products = user_purchases.purchases.reduce((acc, purchase) => {
                return [...acc, ...purchase.purchased_items]
            }, [])

            setSubscriptions(purchase_products)
        } catch (error) {
            console.error("Erro ao buscar páginas:", error);
        }

    }

    function parseJwtToken(token) {
        return jwtDecode(token);
    }

    function getToken() {
        return localStorage.getItem('token');
    }

    function setToken(token) {
        localStorage.setItem('token', token);
    }

    function setSubscriptionCache(subscriptions) {
        localStorage.setItem('subscriptions', JSON.stringify(subscriptions));
    }

    function getSubscriptionCache() {
        const subscriptions = JSON.parse(localStorage.getItem('subscriptions'));
        if (subscriptions) return subscriptions
        return []
    }

    function userIsLoggedIn() {
        return isLoaded
    }

    function getCachePages() {

        try {
            return JSON.parse(localStorage.getItem('pages'));
        } catch (error) {
            return null
        }
    }

    function setCachePage(pages) {
        localStorage.setItem('pages', JSON.stringify(pages));
    }

    const fetchPages = async () => {
        try {
            const response = await fetch(`${config.baseUrl}/pages/user/${user.id}`);
            return await response.json();
        } catch (error) {
            alert("Erro ao buscar paginas atualizadas")
            console.error("Erro ao buscar páginas:", error);
        }
    };

    const findOrCreateUser = async ({ id_user, email }) => {

        const response = await fetch(`${config.baseUrl}/user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id_user,
                email,
            }),
        });

        const result = await response.json();
        setToken(result.token)
        const user = parseJwtToken(result.token)
        return user
    };

    const handleDelete = async (page) => {
        setIsDeleteModalOpen(false);
        setIsLoadingPages(true)

        try {
            const response = await fetch(`${config.baseUrl}/pages`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id_page: page.id_page }),
            });
            const updatedPages = pages.filter(pg => pg.id_page != page.id_page)
            setPages(updatedPages)
            setIsDeleteModalOpen(false);
            console.log("atualizou paginas localmente", pages)


            const newPages = await fetchPages()
            setPages(newPages);
            setCachePage(newPages);
            setIsLoadingPages(false)
            console.log("atualizou paginas from origin", pages)
        } catch (error) {
            setIsLoadingPages(false)
            setIsDeleteModalOpen(false); // Fecha o modal em caso de erro
            alert("Erro ao excluir página, tente novamente");
        }
    };

    const handleCreatePage = async () => {

        if (userCanCreateMorePages()) navigate(`/edit/`);
        setIsUpdatePlanModalOpen(true)
    };

    const openDeleteModal = (page) => {
        setPageToDelete(page); // Define a página que será deletada
        setIsDeleteModalOpen(true); // Abre o modal
    };

    const openOptionsModal = (page) => {
        setSelectedPage(page)
        setIsOptionsModalOpen(true); // Abre o modal
    };

    const closeOptionsModal = () => {
        setIsOptionsModalOpen(false); // Fecha o modal
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false); // Fecha o modal
    };

    const closeUpdatePlanModal = () => {
        setIsUpdatePlanModalOpen(false); // Fecha o modal
    };

    function openEditPage() {
        navigate(`/edit/${selectedPage.id_page}`, { state: { page: selectedPage } });
    }

    function openQrcode() {
        window.open(selectedPage.qrcode, "_blank")
    }

    function openPlansPage() {
        navigate(`/plans`);
    }

    function openContentPage() {
        navigate(`/content/${selectedPage.id_page}`, { state: { page: selectedPage } });
    }

    function openPrintPage() {
        navigate(`/print/${selectedPage.id_page}`, { state: { page: selectedPage } });
    }

    const numberOfUserPagesAllowed = () => {
        const subscriptions = getUserSubscriptions()
        return subscriptions.reduce((acc, subscriptions) => {
            if (subscriptions.name == 'Premium') return acc + 10
            if (subscriptions.name == 'Basic') return acc + 1
        }, 0)
    }

    const userCanCreateMorePages = () => {
        return true
        // return pages.length > numberOfUserPagesAllowed()
    }

    return (
        <div className="dashboard-container">
            {isLoadingPages ? <Loading />
                : (
                    <div>

                        <div className="dashboard-header">
                            <h3>Páginas</h3>
                            <button className="create-page-button action-button edit-button green-button" onClick={() => handleCreatePage()}>
                                Criar página
                            </button>
                        </div>

                        <ul className="pages-list">


                            {(pages && pages.length > 0) ? pages.map((page) => (
                                <li key={page.id_page} className="page-item">
                                    <img src={page.image_url}></img>
                                    <span className="page-title" onClick={() => openOptionsModal(page)}>{page.title}</span>
                                    <button onClick={() => openOptionsModal(page)}
                                        className="options-button">
                                        <SlidersHorizontal size={20} />
                                    </button>
                                </li>
                            )) : (<div></div>)}
                        </ul>
                    </div>
                )}

            {/* Modal de Confirmação de Exclusão */}
            {isDeleteModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Você tem certeza que deseja excluir esta página?</h2>
                        <p>{pageToDelete?.title}</p>
                        <div className="modal-actions">
                            <button className="action-button edit-button" onClick={closeDeleteModal}>cancelar</button>
                            <button className="action-button edit-button" onClick={() => handleDelete(pageToDelete)}>confirmar</button>
                        </div>
                    </div>
                </div>
            )}

            {isOptionsModalOpen && (
                <div className="modal-overlay" onClick={() => closeOptionsModal()}>
                    <div className=" modal-content modal-content-action">
                        {/* <h2>O que voce deseja fazer?</h2> */}
                        {/* <p>{pageToDelete?.title}</p> */}
                        <div className="button-group vertical-button-group">
                            <button onClick={() => openEditPage()}
                                className="action-button edit-button aligned-button">
                                <Edit3 size={20} /> Editar
                            </button>
                            <button onClick={() => openContentPage()}
                                className="action-button edit-button aligned-button">
                                <Eye size={20} /> Visualizar
                            </button>
                            <button onClick={() => openPrintPage()}
                                className="action-button edit-button aligned-button">
                                <Printer size={20} /> Imprimir qrcode
                            </button>
                            <button
                                className="action-button edit-button aligned-button"
                                onClick={() => openQrcode()}
                            >
                                <QrCode size={20} />Abrir QRcode
                            </button>
                            <button
                                className="action-button edit-button aligned-button"
                                onClick={() => openDeleteModal()}
                            >
                                <Trash size={20} /> Deletar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isUpdatePlanModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Voce atingiu seu limite de {numberOfUserPagesAllowed()} paginas. Gostaria de comprar mais paginas?</h2>
                        <p>{pageToDelete?.title}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;
