export function getPagesCache() {
    return JSON.parse(localStorage.getItem('pages'));
}

export function setPagesCache(pages) {
    localStorage.setItem('pages', JSON.stringify(pages));
}

export function getActualPageCache() {
    return JSON.parse(localStorage.getItem('actual_page'));

}

export function setActualPageCache(page) {
    localStorage.setItem('actual_page', JSON.stringify(page));
}