import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid"; // Instale com `npm install uuid`
import { useNavigate } from "react-router-dom";
import { Eye, Check, X, Upload } from "lucide-react";
import Loading from '../components/LoadingComponent'; // Caminho relativo para o componente Loading
import config from "../helpers/configHelper";
import { isPremiumSubscriber } from "../helpers/userHelper";

const CreatePage = () => {
    const navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [isLoadingPages, setIsLoadingPages] = useState(false); // Novo estado para controlar o carregamento das páginas
    const [page, setPage] = useState({}); // Novo estado para controlar o carregamento das páginas

    useEffect(() => {
        init();
    }, []);

    async function init() {

    }

    function getFirstPageCache() {
        return JSON.parse(localStorage.getItem('first_page'));
    }

    function setFirstPageCache(pages) {
        localStorage.setItem('first_page', JSON.stringify(pages));
    }

    const handleSave = async () => {

        const firstPage = {
            ...page,
            title,
            description,
            image_url: imageUrl,
            video_url: videoUrl,
        }

        setFirstPageCache(firstPage)
        navigate("/plans")

    };

    const handlePreview = () => {

        const page = {
            title,
            description,
            image_url: imageUrl,
            video_url: videoUrl,
        }

        navigate(`/content/`, { state: { page } })
    }

    const handleImageUpload = async (event) => {

        setIsLoadingPages(true)
        const file = event.target.files[0];

        if (!file || !file.type.startsWith("image/")) {
            alert("Por favor, selecione apenas arquivos de imagem.");
            return;
        }

        const reader = new FileReader();
        reader.onload = async () => {
            const base64Image = reader.result;

            try {
                const response = await fetch(
                    `${config.baseUrl}/images`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            id: uuidv4(),
                            image: base64Image,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Erro ao fazer upload da imagem.");
                }

                const data = await response.json();
                setImageUrl(data.url);
                setVideoUrl(null);
                setIsLoadingPages(false)

            } catch (error) {
                setIsLoadingPages(false)

                console.error("Erro no upload da imagem:", error);
            }
        };
        reader.readAsDataURL(file);
    };


    const handleVideoUpload = async (event) => {
        console.log("comecou a enviar video")

        const file = event.target.files[0];
        const MAX_FILE_SIZE_MB = 20;
        setIsLoadingPages(true);

        if (!file) {
            alert("Nenhum arquivo selecionado.");
            setIsLoadingPages(false);
            return;
        }

        // Verificar tipo de arquivo
        if (!file.type.startsWith("video/")) {
            alert("Por favor, selecione um arquivo de vídeo.");
            setIsLoadingPages(false);
            return;
        }

        // Verificar tamanho do arquivo
        const fileSizeMB = file.size / (1024 * 1024); // Convertendo bytes para MB
        if (fileSizeMB > MAX_FILE_SIZE_MB) {
            alert(`O tamanho do vídeo não pode exceder ${MAX_FILE_SIZE_MB} MB.`);
            setIsLoadingPages(false);
            return;
        }

        try {
            const response = await fetch(`${config.baseUrl}/videos`, {
                method: "POST",
                headers: {
                    "Content-Type": file.type, // Envia o tipo do vídeo no cabeçalho
                },
                body: file, // Envia o arquivo diretamente no corpo
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Erro ao enviar o vídeo.");
            }

            alert("Vídeo enviado com sucesso!");
            console.log(data);
            setVideoUrl(data.url);
            setImageUrl(null);
        } catch (error) {
            alert(error.message || "Erro ao enviar o vídeo.");
            console.error(error);
        } finally {
            setIsLoadingPages(false);
        }
    };


    const handleMediaUpload = (event) => {
        const file = event.target.files[0];

        if (!file) {
            alert("Nenhum arquivo selecionado.");
            return;
        }

        if (file.type.startsWith("image/")) {
            // Chama o método para upload de imagem
            handleImageUpload(event);
        } else if (file.type.startsWith("video/")) {
            // Chama o método para upload de vídeo
            handleVideoUpload(event);
        } else {
            alert("Por favor, selecione apenas arquivos de imagem ou vídeo.");
            event.target.value = ""; // Limpa o input
        }
    };

    return (

        <div>
            {isLoadingPages ?
                <Loading />
                : (
                    <div style={styles.editorContainer}>
                        <div style={styles.editorContent}>

                            <h1 style={styles.mainTitle}>Criar Nova Página</h1>

                            <div style={styles.formGroup}>
                                <label style={styles.label}>Título</label>
                                <input
                                    type="text"
                                    placeholder="Digite o título da página"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    style={styles.input}
                                />
                            </div>

                            <div style={styles.formGroup}>
                                <label style={styles.label}>Imagem</label>

                                <label style={styles.fileInputLabel}>
                                    <Upload size={20} /> Escolher imagem ou video
                                    <input
                                        type="file"
                                        accept="image/*,video/*"
                                        onChange={handleMediaUpload}
                                        style={{ display: "none" }}
                                    />
                                </label>


                                {(imageUrl && !videoUrl) && (
                                    <label htmlFor="image-input" style={styles.previewImageContainer}>
                                        <img
                                            src={imageUrl}
                                            alt="Preview"
                                            style={styles.previewImage}
                                        />
                                        <input
                                            type="file"
                                            id="image-input"
                                            accept="image/*,video/*"
                                            onChange={handleMediaUpload}
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                )}

                                {(!imageUrl && videoUrl) && (
                                    <label htmlFor="image-input" style={styles.previewImageContainer}>
                                        <video
                                            src={videoUrl}
                                            controls
                                            alt="Preview"
                                            style={styles.previewImage}
                                        />
                                        <input
                                            type="file"
                                            id="image-input"
                                            accept="image/*,video/*"
                                            onChange={handleMediaUpload}
                                            style={{ display: "none" }}
                                        />
                                    </label>
                                )}

                            </div>

                            <div style={styles.formGroup}>
                                <label style={styles.label}>Descrição</label>
                                <textarea
                                    placeholder="Digite a descrição da página"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    style={styles.textarea}
                                />
                            </div>

                            <div style={styles.buttonContainer}>
                                {/* <button
                                    onClick={() => navigate("/")}
                                    className="action-button edit-button red-button"
                                >
                                    <X size={20} />
                                </button>
                                <button
                                    onClick={() => handlePreview()}
                                    className="action-button edit-button"
                                >
                                    <Eye size={20} />
                                </button> */}

                                <button onClick={handleSave} className="action-button edit-button green-button">
                                    Criar pagina
                                </button>
                            </div>
                        </div>
                    </div>

                )}
        </div>

    );

};

// Estilos movidos para fora do componente
const styles = {
    fullScreenContainer: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'white',
        zIndex: 50,
        overflow: 'auto',
        padding: '2rem'
    },
    previewContainer: {
        maxWidth: '1000px',
        margin: '0 auto',
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        padding: '0.5rem 1rem',
        backgroundColor: 'white',
        border: '1px solid #ddd',
        borderRadius: '0.375rem',
        cursor: 'pointer'
    },
    previewContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2rem',
        paddingTop: '2rem'
    },
    previewTitle: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        color: '#1a1a1a',
        textAlign: 'center'
    },
    previewImageContainer: {
        maxWidth: '800px',
        width: '100%',
        borderRadius: '0.5rem',
        overflow: 'hidden'
    },
    previewImage: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain'
    },
    previewDescription: {
        fontSize: '1.25rem',
        color: '#4a5568',
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'center',
        whiteSpace: 'pre-wrap'
    },
    editorContainer: {
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        padding: '2rem'
    },
    editorContent: {
        maxWidth: '800px',
        margin: '0 auto',
        // backgroundColor: 'white',
        // borderRadius: '0.5rem',
        // boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
        padding: '2rem'
    },
    mainTitle: {

        fontSize: '1.875rem',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '2rem',
        color: '#1a1a1a'
    },
    formGroup: {
        marginBottom: '1.5rem'
    },
    label: {
        display: 'block',
        marginBottom: '0.5rem',
        fontSize: '0.875rem',
        fontWeight: '500',
        color: '#4a5568'
    },
    input: {
        width: '100%',
        padding: '0.75rem',
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        outline: 'none'
    },
    fileInputLabel: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.5rem',
        padding: '0.75rem',
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        cursor: 'pointer',
        backgroundColor: 'white',
        width: '100%',
        background_color: "#f3f4f6",
        color: "#4b5563",
        margin_bottom: '20px',
    },
    textarea: {
        width: '100%',
        minHeight: '150px',
        padding: '0.75rem',
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        outline: 'none',
        resize: 'vertical'
    },
    buttonContainer: {
        display: 'flex',
        gap: '1rem',
        marginBottom: '1.5rem',
        float: "right"
    },
    primaryButton: {
        flex: 1,
        padding: '0.75rem',
        backgroundColor: '#3182ce',
        color: 'white',
        border: 'none',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem'
    },
    secondaryButton: {
        flex: 1,
        padding: '0.75rem',
        backgroundColor: 'white',
        color: '#3182ce',
        border: '1px solid #3182ce',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem'
    },
    cancelButton: {
        flex: 1,
        padding: '0.75rem',
        backgroundColor: 'white',
        color: 'red',
        border: '1px solid red',
        borderRadius: '0.375rem',
        fontSize: '1rem',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.5rem'
    },
};

export default CreatePage;