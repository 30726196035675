const App = () => {
const htmlContent = `
<!DOCTYPE html>
<html lang="pt-BR">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Memorias de Amor</title>
    <style>
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
            font-family: 'Segoe UI', sans-serif;
        }

        body {
            background: #0a0a1a;
            color: white;
        }

        p {
            margin-left: 4px;
            color: white;
            text-align: left;
            margin-bottom: 30px;
        }

        h3 {
            color: white;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 20px;
        }

        /* Header */
        header {
            padding: 20px 0;
        }

        .logo {
            margin-top: 20px;
            width: 200px;
            -webkit-text-fill-color: transparent;
        }

        /* Hero Section */
        .hero {
            min-height: 80vh;
            display: flex;
            align-items: center;
            background: linear-gradient(180deg, rgba(10, 10, 26, 1) 0%, rgba(16, 16, 42, 1) 100%);
        }

        .hero-content {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .hero-text {
            flex: 1;
        }

        .hero-image {
            flex: 1;
            position: relative;
        }

        .hero-image img {
            width: 100%;
            border-radius: 20px;
        }

        h1 {
            font-size: 48px;
            background: linear-gradient(45deg, #ff4b8b, #ff6b6b);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 10px;
            line-height: 1.2;
        }

        .cta-button {
            background: linear-gradient(45deg, #ff4b8b, #ff6b6b);
            color: white;
            padding: 15px 40px;
            border-radius: 30px;
            border: none;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            box-shadow: 0 5px 15px rgba(255, 75, 139, 0.3);
        }

        .cta-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 8px 20px rgba(255, 75, 139, 0.4);
        }

        /* How it Works */
        .how-it-works {
            padding: 100px 0;
            background: #0d0d23;
        }

        .section-title {
            font-size: 36px;
            text-align: center;
            margin-bottom: 50px;
            color: white;
        }

        .steps {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 30px;
            margin-top: 50px;
        }

        .step-card {
            /* background: rgba(255, 255, 255, 0.05); */
            /* padding: 30px; */
            border-radius: 20px;
            text-align: center;
            transition: transform 0.3s ease;
        }

        .step-card img {
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 20px;
            width: 100%;
        }

        .step-card:hover {
            transform: translateY(-5px);
            background: rgba(255, 255, 255, 0.08);
        }

        /* Pricing */
        .pricing {
            background: linear-gradient(180deg, rgba(10, 10, 26, 1) 0%, rgba(16, 16, 42, 1) 100%);
            padding: 50px 0;
        }

        /* .pricing h2 {
            color: #0d0d23;
        } */

        .pricing-cards {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 30px;
            max-width: 1000px;
            margin: 0 auto;
        }

        .price-card {
            background: rgba(255, 255, 255, 0.05);
            padding: 40px;
            border-radius: 20px;
            text-align: center;
            transition: transform 0.3s ease;
            position: relative;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .price-card.premium {
            border: 2px solid #ff4b8b;
        }

        .pricing-cards h3 {
            opacity: 80%;
            text-align: left;
        }

        .price-card.basic h3 {
            text-align: left;
        }

        .price-card:hover {
            transform: translateY(-5px);
        }

        .price {
            display: flex;
            font-size: 62px;
            font-weight: bold;
            margin: 20px 0;
            color: #ff4b8b;
        }

        .price-icon {
            color: green;
            margin-right: 5px;
            font-size: 25px;
        }

        .features {
            margin: 30px 0;
            text-align: left;
        }

        .features li {
            margin: 15px 0;
            list-style: none;
        }

        /* FAQ */
        .faq {
            padding: 100px 0;
            background: #0d0d23;
        }

        .faq-item {
            background: rgba(255, 255, 255, 0.05);
            margin-bottom: 15px;
            border-radius: 15px;
            overflow: hidden;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }

        .faq-question {
            padding: 20px;
            width: 100%;
            text-align: left;
            background: none;
            border: none;
            color: white;
            font-size: 18px;
            cursor: pointer;
            transition: background 0.3s ease;
        }

        .faq-question:hover {
            background: rgba(255, 255, 255, 0.08);
        }

        /* Responsive Design */
        @media (max-width: 768px) {
            .hero-content {
                flex-direction: column;
                text-align: center;
            }

            h1 {
                font-size: 36px;
                text-align: left;
            }

            .pricing-cards {
                grid-template-columns: 1fr;
            }

            .logo {
                display: flex;
                margin: auto;
                margin-top: 25px;
                margin-bottom: 20px;
                width: 200px;
                -webkit-text-fill-color: transparent;
            }
        }
    </style>
</head>

<body>
    <section class="hero">
        <div class="container">
            <img class="logo" src="/logo_comprida.png" alt="Memórias especiais" />
            <div class="hero-content">
                <div class="hero-text">
                    <h1>Surpreenda quem você ama nesse natal</h1>
                    <p>Transforme suas fotos e vídeos em paginas mágicas<br>
                        que viram enfeites de natal ou itens de decoração. <br>
                        Faça agora um <b style="color: #ff4b8b">presente surpresa
                            inesquecível.</b></p>
                    <button onclick="window.location.href='/dashboard';" class="cta-button">Quero fazer minha
                        pagina</button>
                </div>
                <div class="hero-image">
                    <img src="/banner.png" alt="Memórias especiais" />
                </div>
            </div>
        </div>
    </section>

    <section class="how-it-works">
        <div class="container">
            <h2 class="section-title">Como funciona</h2>
            <div class="steps">
                <div class="step-card">
                    <img src="/landing_page/step1.png" alt="Crie sua página" />
                </div>
                <div class="step-card">
                    <img src="/landing_page/step2.png" alt="Crie sua página" />
                </div>
                <div class="step-card">
                    <img src="/landing_page/step3.png" alt="Crie sua página" />
                </div>
                <div class="step-card">
                    <img src="/landing_page/step4.png" alt="Crie sua página" />
                </div>

            </div>
        </div>
    </section>

    <section class="pricing">
        <div class="container">
            <h2 class="section-title darker-title">Preços</h2>
            <div class="pricing-cards">
                <div class="price-card">
                    <h3>Basic</h3>
                    <div class="price">R$29</div>
                    <ul class="features">
                        <li><span class="price-icon">✅</span> 1 Pagina Mágica</li>
                        <li><span class="price-icon">✅</span> 1 Ano de acesso</li>
                        <li><span class="price-icon">❌</span> Use apenas fotos</li>
                    </ul>
                    <button class="cta-button" onclick="window.location.href='/dashboard';">Quero fazer minha
                        página</button>
                </div>
                <div class="price-card premium">
                    <div
                        style="position: absolute; top: -12px; right: -12px; background: #ff4b8b; padding: 5px 15px; border-radius: 20px; font-size: 14px;">
                        MAIS ESCOLHIDO</div>
                    <h3>Premium</h3>
                    <div class="price">R$49</div>
                    <ul class="features">
                        <li><span class="price-icon">✅</span> 10 Paginas Mágicas</li>
                        <li><span class="price-icon">✅</span> Acesso vitalício</li>
                        <li><span class="price-icon">✅</span> Use fotos e vídeos</li>
                    </ul>
                    <button class="cta-button" onclick="window.location.href='/dashboard';">Quero fazer minha
                        página</button>
                </div>
            </div>
        </div>
    </section>


    <!-- <section class="faq">
        <div class="container">
            <h2 class="section-title">Perguntas Frequentes</h2>
            <div class="faq-list">
                <div class="faq-item">
                    <button class="faq-question">O que é a Pagina Magica?</button>
                </div>
                <div class="faq-item">
                    <button class="faq-question">Quais são as formas de pagamento? Tem como parcelar?</button>
                </div>
                <div class="faq-item">
                    <button class="faq-question">Quanto tempo demora para receber o QR Code no e-mail?</button>
                </div>
            </div>
        </div>
    </section> -->

    <section class="pricing" style="text-align: center;">
        <div class="container">
            <h2 class="section-title">Vamos fazer um presente surpresa inesquecível?</h2>
            <p style="margin-bottom: 30px; opacity: 0.8;">Demora menos de 5 minutos!</p>
            <button class="cta-button" onclick="window.location.href='/dashboard';">Quero fazer minha página</button>
        </div>
    </section>
</body>

</html>`

return (
<div>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
</div>
);
};

export default App