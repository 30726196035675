import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useLocation } from "react-router-dom";
import { Edit3, Home } from "lucide-react";
import config from "../helpers/configHelper";

const styles = {
    previewContainer: {
        maxWidth: "1000px",
        margin: "0 auto",
        position: "relative",
        padding: "2rem",
        textAlign: "center",
    },
    previewTitle: {
        fontSize: "2.5rem",
        fontWeight: "bold",
        color: "#1a1a1a",
        textAlign: "center",
        marginBottom: "2rem",
    },
    previewImageContainer: {
        maxWidth: "800px",
        width: "100%",
        borderRadius: "0.5rem",
        overflow: "hidden",
        margin: "0 auto 2rem auto",
    },
    previewVideoContainer: {
        maxWidth: "800px",
        width: "100%",
        borderRadius: "0.5rem",
        overflow: "hidden",
        margin: "0 auto 2rem auto",
    },
    previewImage: {
        width: "100%",
        height: "auto",
        objectFit: "contain",
    },
    previewDescription: {
        fontSize: "1.25rem",
        color: "#4a5568",
        maxWidth: "800px",
        margin: "0 auto",
        whiteSpace: "pre-wrap",
        lineHeight: "1.6",
    },
    editButton: {
        margin: "10px",
        bottom: "20px",
        right: "20px",
        padding: "10px 20px",
        background_color: "#f3f4f6",
        color: "#4b5563",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5)",
    },
    containerButtons: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        color: "rgb(75, 85, 99)",
        border: "none",
        border_radius: "5px",
        cursor: "pointer",
    }
};

function Content() {
    const { id_page } = useParams();
    const { user } = useUser();
    const navigate = useNavigate();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [pageUserId, setPageUserId] = useState(null);
    const [page, setPage] = useState(null);
    const [isLoadingPages, setIsLoadingPages] = useState(true); // Novo estado para controlar o carregamento das páginas
    const location = useLocation();
    const receivedPageData = location.state?.page; // Aqui você acessa os dados passados


    useEffect(() => {
        console.log("id_page", id_page)

        if (!id_page && !receivedPageData) navigate("/")


        if (receivedPageData) {
            setPage(receivedPageData)
            setTitle(receivedPageData.title || "");
            setDescription(receivedPageData.description || "");
            setImageUrl(receivedPageData.image_url || null);
            setVideoUrl(receivedPageData.video_url || null);
            setPageUserId(receivedPageData.id_user || null);
            setIsLoadingPages(false)
            return
        }

        fetchData();

    }, []);

    async function fetchData() {
        try {
            const response = await fetch(
                `${config.baseUrl}/pages/${id_page}`
            );
            const result = await response.json();
            setTitle(result.title || "");
            setDescription(result.description || "");
            setImageUrl(result.image_url || null);
            setVideoUrl(result.video_url || null);
            setPageUserId(result.id_user);
            setPage(result)
            setIsLoadingPages(false)

        } catch (error) {
            setIsLoadingPages(false)
            console.error("Erro ao carregar o conteúdo:", error);
        }
    };

    const handleEdit = () => {
        if (id_page) {
            navigate(`/edit/${id_page}`, { state: { page } });
        } else {
            navigate(`/edit/`, { state: { page } });
        }
    };

    return (
        <div>
            {isLoadingPages ? (
                <div className="loading-container">
                    <img src="/logo.png" alt="Logo da Empresa" className="logo" />
                    <div className="loading-text" id="loading-text"></div>
                </div>
            ) : (
                <div style={styles.previewContainer}>
                    {title && <h1 style={styles.previewTitle}>{title}</h1>}
                    {(imageUrl && !videoUrl) && (
                        <div style={styles.previewImageContainer}>
                            <img src={imageUrl} alt="Preview" style={styles.previewImage} />
                        </div>
                    )}
                    {(videoUrl && !imageUrl) &&
                        <div style={styles.previewImageContainer}>
                            <video src={videoUrl} controls style={styles.previewImage} />
                        </div>
                    }

                    {description && (
                        <p style={styles.previewDescription}>{description}</p>
                    )}
                    {user && user.id === pageUserId && (
                        <div style={styles.containerButtons}>
                            <button className="action-button edit-button" onClick={handleEdit} style={styles.editButton}>
                                <Edit3 size={20} />
                            </button>
                            <button className="action-button edit-button" onClick={() => { navigate("/dashboard") }} style={styles.editButton}>
                                <Home size={20} />
                            </button>

                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Content;
