import React, { useEffect, useState } from 'react';

const Loading = () => {
    const phrases = [
        "Pegando papel e tesoura",
        "Apontando os lápis de cor",
        "Separando so melhores desenhos",
    ];

    const [currentPhrase, setCurrentPhrase] = useState(phrases[0]);
    let currentPhraseIndex = 0;

    // Função para alternar as frases
    const changeLoadingText = () => {
        currentPhraseIndex = (currentPhraseIndex + 1) % phrases.length;
        setCurrentPhrase(phrases[currentPhraseIndex]);
    };

    useEffect(() => {
        const interval = setInterval(changeLoadingText, 4000); // Troca o texto a cada 4 segundos
        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    return (
        <div className="loading-container">
            <img src="/logo.png" alt="Logo da Empresa" className="logo" />
            <div className="loading-text">{currentPhrase}</div>
        </div>
    );
};

export default Loading;
